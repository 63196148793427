#videoContainer {
  text-align: center;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video {

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-button {
  margin: 10px;
}